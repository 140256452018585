
import { useState, useEffect, useRef } from 'react';
import { ReactComponent as MenuSVG } from '../assets/menu.svg';

import './ExportButton.scss';

function ExportButton({ onClick }) {

  const wrapperRef = useRef();

  const [visible, setVisible] = useState(false);

  const handleClickOutside = (event) => {
    if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setVisible(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', (event) => handleClickOutside(event));

    return () => {
      document.removeEventListener('mousedown', (event) => handleClickOutside(event));
    }
  }, []);

  return (
    <div className="export-button" ref={wrapperRef}>
      <button
        className="icon-button"
        onClick={() => setVisible(!visible)}
      >
        <MenuSVG />
      </button>
      { visible ? <div className="export-list">
        <div className="list-wrapper">
          <div className="list-item">
            <div onClick={ onClick ? () => onClick(';') : undefined }>Экспорт CSV</div>
          </div>
          <div className="list-item">
            <div onClick={ onClick ? () => onClick("\t") : undefined }>Экспорт TSV</div>
          </div>
          <div className="list-item">
            <div onClick={ onClick ? () => onClick(null) : undefined }>Выгрузить XLS</div>
          </div>
        </div>
      </div> : <></> }
    </div>
  )
}

export default ExportButton;